export const LangData = {
  en: {
    translation: {
      route: 'Прокласти маршрут',
      details: 'Детальніше',
      hide: 'Сховати',
      meta: {
        title: 'Ужгородський Буддійский центр Діамантового Шляху школи Карма Кагʼю',
      },
      nav: {
        about: 'Про нас',
        buddhism: 'Буддизм',
        schedule: 'Розклад',
        teachers: 'Наші вчителі',
        contact: 'Контакти',
        support: 'Підтримка центру',
      },
      footer: {
        menu: 'Меню',
        contacts: 'Контакти',
        resources: 'Ресурси',
        news: 'Новини',
        copyright: 'УБЦ',
        data: {
          address: ['м. Ужгород', 'вул. Кримська, 14'],
          phone: ['Контактні телефони:', '{{phone1}}', '{{phone2}}'],
          email: ['Електронна адреса:', '{{email}}'],
        },
        sites: [
          'Центральний сайт Українського об’єднання (асоціації) Карма Каг’ю',
          'Центральний сайт буддизму Діамантового Шляху',
          'Ретритний центр "Знамін"',
          'Офіційний сайт Лами Оле Нідала',
          'Офіційний сайт XVII Кармапи Трінлей Тхайе Дордже',
          'Сайт міжнародного центру «Європa-Центр»',
        ],
      },
      sections: {
        about: {
          title: 'Про нас',
          quote:
            'У центрі ми зустрічаємось, щоб черпати позачасову мудрість вчення Будди, медитувати, обмінюватися досвідом, проводити час та брати участь у спільній роботі.',
          paragraphs: [
            'Українське об’єднання буддистів Діамантового шляху лінії Карма Каґ’ю тибетського буддизму на сьогодні є найбільшою буддійською організацією в Україні. До її складу входять 9 буддійських центрів та медитаційних груп.',
            'Ми є одним із понад 600 центрів Діамантового шляху, що діють у світі. Ці центри заснували Лама Оле Нідал і Ханна Нідал на прохання їхнього вчителя та майстра медитації 16-го Кармапи Ранґджунґа Ріґпе Дордже. Сьогодні їх очолює Лама Оле Нідал під духовним керівництвом 17-го Кармапи Трінлея Тхайе Дордже.',
            'Ми регулярно організовуємо лекції українських та іноземних вчителів буддизму – учнів Лами Оле Нідала. Вони призначені для надання достовірної інформації для всіх, хто цікавиться живим практичним буддизмом. Наприклад, це такі теми, як “Буддизм на Заході”, “Буддизм у сучасному світі”,  “Що таке Буддизм Діамантового шляху”.',
            'Завдяки нашій розвиненій і просторій інфраструктурі ми готові організовувати великі курси. Багато друзів з усієї України завжди приїжджають сюди подорожувати та медитувати.',
            'Окрім повсякденної діяльності, яка є можливою завдяки волонтерській роботі учасників центру, ми також реалізуємо проект розширення та адаптації будівлі до потреб практикуючих буддистів.',
            'Діяльність центру повністю фінансується за рахунок членських внесків і пожертвувань, що надходять на наш рахунок або благодійних внесків під час лекцій, курсів та інших заходів.',
          ],
        },
        faq: [
          {
            title: 'Буддизм',
            text: [
              'Буддизм Діамантового шляху — це 2550-річне вчення Будди, пов’язане з потенціалом Просвітлення, що міститься в умі кожної істоти.',
              'Через погляд на те, як працює ум, медитацію як ефективний спосіб пізнати його та працювати з ним, і, нарешті, осмислене свідоме життя ми можемо розвинути всі наші досконалі та позачасові якості. До них належать безстрашність, радість, співчуття, а також мудрість і здатність діяти на благо всіх істот.',
              'Методи Діамантового шляху тривали з часів Будди до наших днів як безперервний потік живої передачі від учителя до учня. Знання традиції Каґ’ю передаються в наших центрах українською мовою та у відкритому світському стилі, властивому нашій культурі.',
            ],
            href: 'Детальніше про буддизм',
          },
          {
            title: 'Медитація',
            text: [
              'Буддійська медитація — це метод роботи з умом, завдяки якому він звільняється від усіх обмежень: від бентежних почуттів, таких як гнів чи прив’язаність, а також від жорстких поглядів, які блокують нашу здатність сприймати справжню природу явищ.',
              'Медитація дозволяє вам перетворити знання буддійських повчань та їхнє інтелектуальне розуміння у ваш власний безпосередній досвід. Завдяки ясним текстам медитацій, складеним майстрами лінії Карма Каґ’ю, і їх поясненням, можна свідомо працювати з умом.',
              'Практики Діамантового шляху дозволяють нам відкрити, що ум за своєю суттю безмежний і позачасовий. Кожна істота має однаковий абсолютний потенціал, який вона може реалізувати так само, як Будда.',
            ],
            href: 'Детальніше про медитацію',
          },
          {
            title: 'Вчення',
            text: [
              'Вчення Будди стосуються ума. Вони є ключем до всього, що ми переживаємо, до того, хто переживає та процесу переживання. За допомогою медитації ми можемо застосувати цю теорію на практиці та реалізувати те, чого навчав Будда.',
              'Для нашої спільноти найважливішими є центри, де ми зустрічаємося для загальних та індивідуальних медитацій, кращого розуміння методів для практики у повсякденному житті. Завдяки взаємному натхненню, спільним цінностям і спільній меті створюється справжня дружба, і ми можемо розвиватися швидше.',
              'Бути буддистом Діамантового шляху не означає носити традиційні шати та приймати чернечі обітниці, а практикувати відповідно до вказівок Будди, щоб будувати значущі стосунки, допомагати іншим, підтримувати один одного у важкі часи та надавати нашому життю глибокого сенсу.',
            ],
            href: 'Детальніше про вчення',
          },
        ],
        teachers: {
          title: 'Наші вчителі',
          list: [
            {
              title: 'Лама Оле Нідал',
              description: [
                'Лама Оле Нідал — один з небагатьох західних людей, хто отримав повну кваліфікацію бути ламою й звання вчителя медитації буддійської традиції Карма Каґ’ю.',

                'У 1969 році Оле Нідал разом зі своєю дружиною Ханною стали першими західними учнями Й.Св. 16-го Кармапи. Після трьох років вивчення буддійської філософії та інтенсивної медитації, що містила також передачу унікальної практики Діамантового шляху — йоги свідомого вмирання (тиб. пхова), Оле Нідал на прохання 16-го Кармапи почав вчити буддизму в Європі.',

                'Кидаючи виклик людським концепціям про життя та буддизм, Лама Оле став основною рушійною силою розповсюдження буддизму на Заході. Він заснував понад 600 центрів Діамантового шляху в 44 країнах світу. Завдяки унікальному синтезу сучасного стилю та давньої мудрості він створив найбільшу спільноту, яка практикує методи Діамантового шляху на Заході. Як сказав Шамар Рінпоче: «У 1973 році Шістнадцятий Кармапа попросив Оле та Ханну вчити і провістив, що вони досягнуть неабияких успіхів у розповсюдженні вчення Будди на Заході. Кармапа дав їм основні практики — Гуру-йогу на Восьмого та Шістнадцятого Кармапу. Вони без жодних вагань дотримувалися своїх самая, втілюючи в життя побажання 16-го Кармапи. Лама Оле несе його активність».',

                'Лама Оле — автор декількох книжок, зокрема «Природа речей» , «Відкриття Діамантового шляху», «Верхи на тигрі», «Будда й любов», «Велика Печать» та ін.',
              ],
              links: {
                site: 'Сайт Лами Оле Нідала',
                qa: 'Питання-відповіді Лами Оле',
              },
            },
            {
              title: 'XVI Кармапа Ранґджунґ Ріґпе Дордже',
              description: [
                'Його Святість Шістнадцятий Кармапа Ранґджунґ Ріґпе Дордже вважається одним із найвидатніших учителів буддизму та майстрів медитації свого часу. Він народився в 1924 році в провінції Дерґе в східному Тибеті, і був знайдений за листом-пророцтвом, залишеним його попередником, Кармапою Кхач’ябом Дордже. У 1931 році молодий Шістнадцятий Кармапа був пострижений у ченці. Потім він почав навчання в традиційній резиденції Кармап, монастирі Цурпху, поглиблюючи знання та медитативну реалізацію сутр, тантр, Махамудри та шести йог Наропи. Ще в дитинстві він виявляв глибоку природну проникливість і часто розповідав вчителям про свої минулі життя.',

                'У 1959 році через ситуацію в Тибеті, який був окупований Китаєм, Кармапа вирішив покинути свою країну. Він зрозумів, що його буддійські вчення принесуть найбільшу користь, коли йому вдасться звільнитися від дедалі міцнішої влади комуністичного загарбника. Отже, з почтом із 160 лам, ченців і мирян Кармапа вирушив із монастиря Цурпху до Бутану. Під проводом Кармапи цій групі вдалося забрати з собою найцінніші статуетки, ритуальні предмети, реліквії та книги, які століттями зберігалися в монастирі в Цурпху.',

                'Губернатор штату Сіккім на північному заході Індії подарував Кармапі монастир Румтек, побудований під час правління Дев’ятого Кармапи Ванґчуґа Дордже (1556-1603). Його Святість приступив до будівництва нового монастиря. Завдяки щедрій підтримці уряду Індії та губернатора Сіккіму будівництво було завершено через чотири роки, і Румтек став офіційною резиденцією Кармапи за межами Тибету, а також центром вивчення, ритуалів і практики буддизму.',

                'У 1974 році Шістнадцятий Кармапа в супроводі групи лам Карма Каґ’ю прибув на Захід, відвідавши Європу, США та Канаду. Вперше люди на Заході мали можливість бути присутніми на Церемонії Чорної Корони, яку там кілька разів проводив Його Святість. У грудні того ж року на запрошення своїх перших західних учнів, Оле та Ханни Нідал, Кармапа приїхав до Копенгагена.',

                'У грудні 1976 року Кармапа прибув до Нью-Йорка, розпочавши свою подорож у США, а наступного року він провів шість місяців у подорожі Європою, де відвідав Швецію, Данію, Німеччину, Нідерланди, Францію та Великобританію. У травні 1980 року Кармапа повернувся на Захід, зупиняючись для лекцій і церемоній у Лондоні, Нью-Йорку, Сан-Франциско та Боулдері, Колорадо.',

                'Його Святість Шістнадцятий Кармапа пішов з життя 5 листопада 1981 року в лікарні поблизу Чикаго.',
              ],
              links: {
                details: 'Детальніше',
              },
            },

            {
              title: 'XVII Кармапа Трінлей Тхайе Дордже',
              description: [
                'Його Святість Сімнадцятий Кармапа Трінлей Тхайе Дордже народився в Тибеті 6 травня 1983 року в сім’ї третього Міпама Ринпоче, великого лами з роду Н’їнґма, і Дечен Вангмо, доньки знатного роду, що походить від короля Ґесара з Лінга. Коли йому виповнилося півтора року, хлопчик почав розповідати всім, що він Кармапа. У 1994 році йому разом із родиною вдалося втекти з Тибету до Непалу, а потім до Індії. У березні того ж року молодий Кармапа прибув до Нью-Делі, де під час церемонії зустрічі Шамар Ринпоче офіційно визнав його 16-м Кармапою. Йому дали імена Трінлей (діяльність Будди), Тхайе (необмежена) і Дордже (незмінна).',

                'У 1997 році Його Святість офіційно запросили до Бутану на церемонію кремації Топґі Ринпоче, де його прийняли з повними почестями. Зазвичай тригодинний шлях від кордону з Бутаном до столиці Тхімпу зайняв сім годин, оскільки тисячі прихильників вітали його по дорозі. 17 жовтня 1997 року три тисячі людей прийшли отримати його благословення, і вся королівська родина з королем і чотирма королевами прийняла його як Сімнадцятого Кармапу.',

                'У жовтні 1999 року Сімнадцятий Кармапа вирушив у своє перше міжнародне турне, навчаючи та даючи посвяти тисячам людей у ​​Сінгапурі та Тайвані. Початок нового тисячоліття ознаменувався першим історичним візитом на Захід. У Дюссельдорфі, Німеччина, його з ентузіазмом прийняли 6000 студентів із кількох сотень центрів Діамантового шляху та понад 30 країн.',

                'Влітку 2003 року Й. Св. Сімнадцятий Кармапа провів свою першу офіційну програму в Сполучених Штатах. 19 і 20 липня під час зустрічі, організованої в Сан-Франциско американськими центрами буддизму Діамантового Шляху, він дав посвячення Карма Пакші і Будди Медицини.',

                'У грудні 2003 року, після формального завершення навчання, Сімнадцятий Кармапа був проголошений «Великим майстром тантри» (санск. Ваджрачарья).',

                'Зараз Його Святість проживає в Калімпонзі, Індія.',
              ],
              links: {
                details: 'Детальніше',
                site: 'Сайт XVII Кармапи Трінлей Тхайе Дордже',
              },
            },
          ],
        },
        center: {
          title: 'Історія Ужгородського Буддійського Центру',
          subtitle: 'Діамантового шляху лінії Карма Каґ’ю',
          paragraphs: [
            'Перші практики методів Діамантового шляху з’явилися в нашому місті ще на самому початку 90-х років. Протягом багатьох років ми збиралися в гарному приватному будинку друзів, частина якого використовувалася для роботи центра. Сам центр був зареєстрований рішенням Закарпатської облдержадміністрації в грудні 1994 року після першого візиту до нашого міста Лами Оле Нідала.',

            'В 1995 р. ми видали нашу першу книгу «108 вопросов йогину» в перекладі з угорської, а в 1997 р. — український переклад книги Лами Оле Нідала «The Way Things Are» під назвою «Дійсність як вона є. Живий підхід до Буддизму для сучасного світу». У 2006 році Лама Оле благословив будівництво нового центра поруч і закликав енергії Буд п’ятьох напрямків. А далі… надихаючі медитаційні курси, багато роботи і спільна практика кожного вечора.',

            'Зараз ми зустрічаємося в великому будинку по вул. Кримський 14, а також піклуємося про ретритний центр у Карпатах.',
          ],
          photos: [
            'Лекція Лами Оле з дружиною Ханною Нідал, Ужгород  1994',
            'Медитаційний ретрит, Ужгород  2022',
          ],
        },
        schedule: {
          title: 'Розклад',
          main: 'Кожного дня о <0>{{time}}</0> в центрі проходить спільна медитація на 16-го Кармапу.',
          description:
            'Медитація займає близько півгодини, також можна отримати пояснення щодо практики.',
        },
      },
      support: {
        text: [
          'З 1994 року буддійський центр існує на благодійні внески, які допомагають безперервно продовжувати його роботу, будівництво та благоустрій будівлі.',
          'Підтримати проект можна банківським переказом.',
        ],
        account: {
          important: 'ВАЖЛИВО!!!',
          template: [
            'В призначенні платежу необхідно обов’язково писати тільки такий текст: <0>“благодійна пожертва на статутну діяльність, прізвище та ім’я”<0>',
            'РЕЛІГІЙНИЙ ЦЕНТР УКРАЇНСЬКОГО ОБ’ЄДНАННЯ БУДДИСТІВ ШКОЛИ КАРМА КАГ’Ю (скорочено РЦ УО БШКК)',
            'Код ЄДРПОУ <0>{{edrpou}}</0>',
            'АТ КБ “Приватбанк”, м. Київ',
            // 'IBAN <0>{{iban_kk}}</0> - р/р грн Релігійного Центру УО БШКК',
            'IBAN <0>{{iban_ubc}}</0> - р/р для благодійних внесків на проект будівництва Ужгородського  буддійського центру “UZHHOROD PROJECT” пріоритетний проект',
          ],
        },
      },
    },
  },
};
