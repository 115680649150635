import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LangData } from '../data/lang';

const config = {
  resources: LangData,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(initReactI18next).init(config);

export default i18n;
