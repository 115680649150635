import { Container } from 'react-bootstrap';
import { API_KEY, PLACE_ID, ZOOM_LEVEL } from '../constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AddressUrl } from '../data';

export function EmbedMap() {
  return (
    <Container
      fluid
      className="embedMinHeightDoubled maps roundedTop g-0 position-relative overflow-hidden mb-n2 mb-md-0 mt-0 mt-md-5"
    >
      <ButtonRoute />
      <iframe
        className="embedHideControls w-100"
        title="map"
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=place_id:${PLACE_ID}&zoom=${ZOOM_LEVEL}`}
      />
    </Container>
  );
}

function ButtonRoute() {
  const { t } = useTranslation();
  return (
    <div className="route-btn fw-bold">
      <Link to={AddressUrl}>{t('route')}</Link>
    </div>
  );
}
