export const LINK_SUPPORT = 'support';
export const ANCHOR_ABOUT = 'about';
export const ANCHOR_BUDDHISM = 'buddhism';
export const ANCHOR_SCHEDULE = 'schedule';
export const ANCHOR_TEACHERS = 'teachers';
export const ANCHOR_CONTACT = 'contact';

export const SLIDESHOW_INTERVAL = 5000;

export const API_KEY = process.env.REACT_APP_API_KEY as string;
export const PLACE_ID = process.env.REACT_APP_PLACE_ID;
export const ZOOM_LEVEL = 15;

export const MAIN_CONTENT_BS_WIDTH = 10;

export const CENTER_PHOTO_FOLDER = 'history';
export const SLIDER_PHOTO_FOLDER = 'slider';
export const TEACHERS_PHOTO_FOLDER = 'teachers';
export const SEPARATORS_PHOTO_FOLDER = 'separators';

export const TEACHER_ID_LAMA_OLE = 'lama_ole.jpg';
export const TEACHER_ID_KARMAPA_16 = 'karmapa16.jpg';
export const TEACHER_ID_KARMAPA_17 = 'karmapa17.jpg';
