import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { generateSrcSet } from '../misc/utils';

const BUNDLE = require(`../assets/bundle.json`) as TCompressedAsset[];

export function Heading({ children, className }: any) {
  return (
    <Row className="g-0">
      <Col xs={12} md={11} lg={10} className="mx-auto">
        <h1 className={`${className} fs-1 fw-bold`}>{children}</h1>
      </Col>
    </Row>
  );
}

export function Bumper({ className = '' }: TBumperProps) {
  return <div className={`${className} bumper roundedTop d-md-none`} />;
}

export function useAssetBundle(namespace: string): TCompressedAsset[] {
  return BUNDLE.filter((v) => v.folder === namespace).sort();
}

export function useAssetId(id: string): TCompressedAsset | undefined {
  return BUNDLE.find((v) => v.id === id);
}

export function PreloadImages({ src }: TPreloadProps) {
  const images = src.reduce((a, i) => `${a} url(${i})`, '');
  return (
    <div
      style={{
        position: 'absolute',
        width: 0,
        height: 0,
        overflow: 'hidden',
        zIndex: -1,
        content: images,
      }}
    />
  );
}

export function Spoiler({ children }: ToggleProps) {
  const { t } = useTranslation();
  const [isOpen, setOpenInfo] = useState(false);

  return (
    <>
      <p
        className="text-secondary text-decoration-underline user-select-none d-md-none"
        onClick={() => setOpenInfo(!isOpen)}
      >
        {!isOpen ? t('details') : t('hide')}...
      </p>
      <div className={`${!isOpen && 'd-none'} d-md-block`}>{children}</div>
    </>
  );
}

export function ResponsiveImage({ image, className = '' }: any) {
  const { id, folder, ...urls } = image;

  return <img className={className + ' responsiveImage'} srcSet={generateSrcSet(urls)} />;
}
