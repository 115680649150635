import { Col, Container, Ratio } from 'react-bootstrap';
import { YTLink } from '../data';

export function EmbedYoutube() {
  return (
    <Container fluid className={`bg-dark g-0 mt-3 mt-md-5`}>
      <Container fluid="xl" className="g-0">
        <Col lg={12}>
          <Ratio aspectRatio="16x9">
            <iframe
              className="h-100 w-100"
              src={YTLink}
              title="YouTube video player"
              allowFullScreen
            ></iframe>
          </Ratio>
        </Col>
      </Container>
    </Container>
  );
}
