import './types.d';

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { RouterProvider } from 'react-router-dom';

import i18n from './config/i18n';

import router from './router';
import { I18nextProvider } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';

const rootEl = document.getElementById('root') as HTMLElement;

if (!rootEl.hasChildNodes()) {
  const root = ReactDOM.createRoot(rootEl);
  root.render(<Application />);
} else {
  ReactDOM.hydrateRoot(rootEl, <Application />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function Application() {
  return (
    <React.StrictMode>
      <HelmetProvider>
        <I18nextProvider i18n={i18n}>
          <RouterProvider router={router} />
        </I18nextProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
}
