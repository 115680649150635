import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function cn(...args: Array<string | boolean>) {
  return {
    className: args.filter((i) => typeof i === 'string').join(' '),
  };
}

export function getLink(item: TMenuItem) {
  return item.destination || `/#${item.name}`;
}

export function stripUrl(url: string) {
  return url.replace(/https?:\/\/(www\.)?/, '').replace(/\/$/, '');
}

export function splitArray(arr: any[], thresh: number = 5) {
  return [arr.slice(0, thresh), arr.slice(thresh)];
}

export function generateSrcSet(images: Record<string, string>) {
  const src = Object.entries(images);

  return src
    .map(([name, url]) => {
      const [w, m] = name
        .slice(1)
        .split('_')
        .map((v) => +v);
      return `${url} ${Math.floor(w * m)}w`;
    })
    .join(', ');
}

export function useScrollOnMount() {
  const { hash } = useLocation();

  useEffect(() => {
    const ref = document.getElementById('root');
    ref?.scrollIntoView();
  }, []);

  useEffect(() => {
    const dest = hash || '#root';
    const ref = document.querySelector(dest);
    ref?.scrollIntoView();
  }, [hash]);
}
export function findLargestPicture(img: Partial<TCompressedAsset>): string {
  const largest = Math.max(...Object.keys(img).map((k) => +k.slice(1).split('_')[0]));

  return img[`r${largest}_1`] as string;
}

export function extractPictureUrls(assets: TCompressedAsset[]): string[] {
  return assets.reduce((acc, asset) => {
    const { id, folder, ...img } = asset;
    return [...acc, ...Object.values(img)];
  }, [] as string[]);
}
