import { useState } from 'react';
import { Carousel } from 'react-bootstrap';

import { SLIDER_PHOTO_FOLDER, SLIDESHOW_INTERVAL } from '../constants';
import { Bumper, PreloadImages, ResponsiveImage, useAssetBundle } from './Utils';
import { extractPictureUrls } from '../misc/utils';

export function SliderPhoto() {
  const [index, setIndex] = useState(0);
  const IMG_SLIDESHOW_ITEMS = useAssetBundle(SLIDER_PHOTO_FOLDER);
  return (
    <div className="sliderWrapper">
      <Carousel
        activeIndex={index}
        onSelect={setIndex}
        controls={false}
        wrap
        interval={SLIDESHOW_INTERVAL}
        className="sliderPhoto"
      >
        {IMG_SLIDESHOW_ITEMS.map((image) => {
          return (
            <Carousel.Item key={image.id} className="sliderPhotoWrapper">
              <ResponsiveImage image={image} />
            </Carousel.Item>
          );
        })}
      </Carousel>
      <Bumper className="sliderBumper" />
      <PreloadImages src={extractPictureUrls(IMG_SLIDESHOW_ITEMS)} />
    </div>
  );
}
