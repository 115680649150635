import { Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useScrollOnMount } from '../misc/utils';

const vals = {
  edrpou: process.env.REACT_APP_DONATE_CODE_EDRPOU,
  iban_ubc: process.env.REACT_APP_DONATE_IBAN_UBC,
  iban_kk: process.env.REACT_APP_DONATE_IBAN_KK,
};
export function Support() {
  const { t } = useTranslation();
  const text = t('support.text', { returnObjects: true }) as string[];
  const template = t('support.account.template', { returnObjects: true }) as string[];
  useScrollOnMount();

  return (
    <Container fluid="xxl" className="py-5">
      <Col className="supportWrapper pb-5">
        <Row className="g-0">
          <Col xs={10} xxl={12} className="mx-auto">
            <h1 className={`text-secondary fs-1 fw-lg-bold`}>{t('nav.support')}</h1>
          </Col>
        </Row>
        <Row className="g-0">
          <Col xs={10} className="mx-auto mt-5 tj">
            {text.map((par, i) => (
              <p key={i}>{par}</p>
            ))}
          </Col>
        </Row>
        <Row className="g-0">
          <Col xs={10} className="mx-auto bg-info h-100 p-5 border-radius rounded-5 mt-5">
            <div className="border-start border-warning border-5 ps-4 mt-3">
              <p>
                <b>{t('support.account.important')}</b>
              </p>

              {template.map((_, i) => (
                <p key={i}>
                  <Trans
                    i18nKey={`support.account.template.${i}`}
                    values={vals}
                    components={[<span className="fw-bold"></span>]}
                  />
                </p>
              ))}
            </div>
          </Col>
        </Row>
      </Col>
    </Container>
  );
}
