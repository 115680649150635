import { Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { MEDITATION_TIME } from '../data';
import { ANCHOR_SCHEDULE } from '../constants';
import { Heading, ResponsiveImage, useAssetId } from './Utils';

export function Schedule() {
  const { t } = useTranslation();
  const images = useAssetId('spoiler_bottom.png');

  return (
    <Container
      id={ANCHOR_SCHEDULE}
      fluid
      className="imageSeparator roundedTop position-relative d-flex align-items-center justify-content-center mt-5 mb-n5 mb-md-5"
    >
      <div className="tinted roundedTop w-100 h-100 position-absolute z-0">
        <ResponsiveImage image={images} className="h-100" />
      </div>
      <Container fluid="xxl" className="position-relative z-1 text-light py-5">
        <Heading className="mb-5">{t('sections.schedule.title')}</Heading>
        <Col xs={12} md={12} lg={10} className="tj mx-auto">
          <Row className="g-0">
            <Col xs={12} md={10} className="offset-md-1 mb-5 mb-lg-0">
              <p className="mainTime">
                <Trans
                  i18nKey="sections.schedule.main"
                  values={{ time: MEDITATION_TIME }}
                  components={[<span className="fw-lg-bold">1:08</span>]}
                />
              </p>
              <p className="secondaryText">{t('sections.schedule.description')}</p>
            </Col>
          </Row>
        </Col>
      </Container>
    </Container>
  );
}
