import { useTranslation } from 'react-i18next';
import { ResponsiveImage, useAssetBundle } from './Utils';
import { CENTER_PHOTO_FOLDER } from '../constants';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Ratio from 'react-bootstrap/esm/Ratio';

export function AboutCenter() {
  const { t } = useTranslation();
  const images = useAssetBundle(CENTER_PHOTO_FOLDER);

  return (
    <Col className="px-3 mt-md-5 pt-5">
      <Row className="g-0">
        <Col xs={12} md={11} lg={10} className="mx-auto">
          <h1 className="text-secondary fs-1 mb-4 fw-bold">{t('sections.center.title')}</h1>
          <h5 className="text-secondary fs-5 fw-bold">{t('sections.center.subtitle')}</h5>
        </Col>
      </Row>
      <Row className="tj g-0 mt-5 mt-md-4 mt-lg-5">
        <Col xs={12} md={8} lg={10} className="mx-auto">
          <Row className="align-items-end">
            <Col xs={12} lg={6} xl={7} className="mb-3 mb-lg-0">
              <Photo asset={images[0]} description={t('sections.center.photos.0')} />
            </Col>
            <Col xs={12} lg={5} xl={4}>
              {t('sections.center.paragraphs.0')}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12} lg={5} xl={4} className="mb-3 mb-lg-0 offset-lg-1">
              {t('sections.center.paragraphs.1')}
            </Col>
            <Col xs={12} lg={6} xl={7}>
              <Photo asset={images[1]} description={t('sections.center.photos.1')} />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12} lg={10} xl={10} className="offset-lg-1">
              {t('sections.center.paragraphs.2')}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

function Photo({ asset, description }: TPhotoProps) {
  return (
    <div className="position-relative">
      <Ratio aspectRatio="16x9" className="position-relative">
        <ResponsiveImage image={asset} className="centerPhoto" />
      </Ratio>
      <p className="centerPhotoDescription text-light position-absolute">{description}</p>
    </div>
  );
}
