import { Col, Container, Offcanvas, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import LOGO from '../assets/Logo.png';

import {
  ANCHOR_ABOUT,
  ANCHOR_BUDDHISM,
  ANCHOR_SCHEDULE,
  ANCHOR_TEACHERS,
  ANCHOR_CONTACT,
  LINK_SUPPORT,
} from '../constants';

import { useTranslation } from 'react-i18next';
import { getLink } from '../misc/utils';
import { useState } from 'react';

const items: TMenuItem[] = [
  { name: ANCHOR_ABOUT },
  { name: ANCHOR_BUDDHISM },
  { name: ANCHOR_SCHEDULE },
  { name: ANCHOR_TEACHERS },
  { name: ANCHOR_CONTACT },
  { name: LINK_SUPPORT, destination: LINK_SUPPORT },
];

export function Header() {
  return (
    <Container fluid className="headerParent">
      <Container fluid="lg" className="headerWrapper g-0 p-2 p-lg-4">
        <Row xs={4} lg={8} xl={10} className="g-0 justify-content-between">
          {/* button */}
          <Col xs={1} className="d-xl-none">
            <MenuOverlay />
          </Col>

          {/* links */}
          <Col lg={8} className="d-none d-xl-flex justify-content-start align-items-center">
            <MenuRegular skipDonate />
          </Col>

          <Col
            xs={10}
            sm={6}
            md={5}
            lg={4}
            className="d-flex justify-content-end align-items-center"
          >
            <LogoButton />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function MenuOverlay() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  function toggleMenu() {
    setMenuOpen((p) => !p);
  }

  return (
    <>
      <MenuButton onClick={toggleMenu} />
      <Offcanvas show={isMenuOpen} onHide={toggleMenu} backdrop={false} className="headerOffcanvas">
        <Offcanvas.Header closeButton>
          <div />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <MenuRegular onClick={toggleMenu} skipDonate />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export function MenuRegular({ onClick, skipDonate }: Partial<TMenuItemProps>) {
  let menu = items;
  if (skipDonate) {
    menu = menu.slice(0, -1);
  }

  return (
    <>
      {menu.map((menuItem, i) => (
        <MenuItem key={i} item={menuItem} onClick={onClick} />
      ))}
    </>
  );
}

function LogoButton() {
  return (
    <Link to="/" className="headerLogoButton">
      <img src={LOGO} alt="Diamond Way Logo" />
    </Link>
  );
}

function MenuButton({ onClick }: Partial<TMenuItemProps>) {
  return (
    <div
      onClick={onClick}
      className={`headerMenuButton d-flex justify-content-center align-items-center`}
    >
      <i className="bi bi-list"></i>
    </div>
  );
}

function MenuItem({ item, onClick = () => {} }: TMenuItemProps) {
  const { t } = useTranslation();
  const { hash, pathname } = useLocation();

  const activeClass = getActiveClass(item, hash, pathname);

  const dest = getLink(item);

  function scrollToDest() {
    onClick();
    // const ref = document.getElementById(item.name);
    // ref?.scrollIntoView();
  }

  return (
    <div className="headerMenuItemWrapper d-block me-2 ms-2 me-xl-4 me-xxl-5 ms-xl-0 mb-5 mb-xl-0">
      <Link
        to={dest}
        className={`headerMenuItem ${activeClass} text-decoration-none fw-bold pb-lg-1 pb-xxl-2`}
        onClick={scrollToDest}
      >
        {t(`nav.${item.name}`)}
      </Link>
    </div>
  );
}

function getActiveClass(item: TMenuItem, hash: string, pathname: string): string {
  const isActive = hash === `#${item.name}` || pathname === `/${item.destination}`;

  return isActive ? 'headerMenuItemActive' : '';
}
