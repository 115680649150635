import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { LINK_SUPPORT } from './constants';
import { Main } from './pages/Main';
import { Support } from './pages/Support';

import { App } from './App';
import { NotFound } from './pages/404';

export default createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Main />} />
      <Route path={`/${LINK_SUPPORT}`} element={<Support />} />
      <Route path="*" element={<NotFound />} />
    </Route>,
  ),
);
