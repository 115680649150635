import { ANCHOR_ABOUT, ANCHOR_BUDDHISM, ANCHOR_CONTACT, ANCHOR_TEACHERS } from '../constants';

// FOOTER

export const footerMenuItems: TMenuItem[] = [
  { name: ANCHOR_ABOUT, destination: null },
  { name: ANCHOR_BUDDHISM, destination: null },
  { name: ANCHOR_TEACHERS, destination: null },
  { name: ANCHOR_CONTACT, destination: null },
];

export const siteUrls: Record<string, string> = {
  BUDDHISM_UA_KARMAPA_17: 'https://buddhism.ua/vchiteli/karmapi/trinlye-thaye-dordzhe/',
  BUDDHISM_UA_KARMAPA_16: 'https://buddhism.ua/vchiteli/karmapi/rangdzhung-rigpe-dordzhe/',
  SITE_ZNAMIN: 'http://znamin.eu/ua.html',
  SITE_LAMA_OLE: 'https://lama-ole-nydahl.org/',
  SITE_EC: 'http://www.europe-center.org',
  SITE_BUDDHISM_UA: 'https://www.buddhism.ua',
  SITE_DW_ORG: 'https://www.diamondway-buddhism.org/',
  BUDDHISM_UA_LAMA_OLE_QA: 'https://buddhism.ua/vchennya/vidpovidaye-lama-ole/',
  SITE_KARMAPA_17: 'http://www.karmapa.org',
  BUDDHISM_UA_REF_BUDDHISM: 'https://buddhism.ua/buddhism/',
  BUDDHISM_UA_REF_MEDITATION: 'https://buddhism.ua/praktika/meditaciya/',
  BUDDHISM_UA_REF_TEACHING: 'https://buddhism.ua/vchennya/',
};

export const contactInfo: Record<string, TContactItem | string> = {
  phone1: '+38 (050) 9703231',
  phone2: '',
  email: 'uzhgorod@buddhism.ua',
  social: {
    description: 'Facebook:',
    items: ['uzhgorodbuddhism'],
  },
};

export const YTLink = 'https://www.youtube.com/embed/wDUIFyf9fCE';

export const MEDITATION_TIME = '20:00';

export const AddressUrl = 'https://goo.gl/maps/pdU9wkEJTUoW72Ez9';

export const FacebookEmbedId = 144723;
