import './App.scss';

import { Outlet } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useAssetBundle } from './components/Utils';
import { findLargestPicture } from './misc/utils';
import { SLIDER_PHOTO_FOLDER } from './constants';

export function App() {
  const { t } = useTranslation();
  //
  const [image] = useAssetBundle(SLIDER_PHOTO_FOLDER);
  const { id, folder, ...img } = image as TCompressedAsset;

  const src = findLargestPicture(img as Partial<TCompressedAsset>);

  return (
    <>
      <Helmet>
        <title>{t('meta.title')}</title>
        <meta name="description" content={`${t('meta.title')}`} />
        <meta property="og:title" content={`${t('meta.title')}`} />
        <meta property="og:url" content={`//${process.env.REACT_APP_SITE_DOMAIN}`} />
        <meta property="og:description" content={`${t('meta.title')}`} />
        <meta property="og:image" content={`http://${process.env.REACT_APP_SITE_DOMAIN}${src}`} />:
      </Helmet>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}
