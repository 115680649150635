import { Bumper, ResponsiveImage } from './Utils';

export function ImageSeparator({ image, bumper = false }: TImageSeparatorProps) {
  return (
    <>
      <div className={`imageSeparator roundedTop mt-5 ${bumper ? 'mb-n5 mb-md-0' : ''}`}>
        <ResponsiveImage image={image} />
      </div>
      {bumper && <Bumper />}
    </>
  );
}

type TImageSeparatorProps = {
  image?: TCompressedAsset;
  bumper?: boolean;
};
