import { Carousel, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import { SLIDESHOW_INTERVAL } from '../constants';
import { Link } from 'react-router-dom';
import { Heading } from './Utils';
import { siteUrls } from '../data';

const references: string[] = [
  siteUrls.BUDDHISM_UA_REF_BUDDHISM,
  siteUrls.BUDDHISM_UA_REF_MEDITATION,
  siteUrls.BUDDHISM_UA_REF_TEACHING,
];

export function SliderText() {
  const ref = useRef(null!);
  // switch slides causing scroll flickering
  const isOnScreen = useOnScreen(ref);

  const { t } = useTranslation();
  const keys = t('sections.faq', { returnObjects: true }) as any[];

  return (
    <Col
      xs={11}
      lg={8}
      className={`d-flex sliderWrapper sliderWrapperText mx-auto align-items-center pt-5`}
      ref={ref}
    >
      <Carousel
        variant="dark"
        fade={false}
        interval={isOnScreen ? SLIDESHOW_INTERVAL : null}
        controls
        prevIcon={<SliderIcon icon="bi-arrow-left" />}
        nextIcon={<SliderIcon icon="bi-arrow-right" />}
      >
        {keys.map((slide, i) => (
          <Carousel.Item key={slide.title}>
            <SlideInfo
              heading={slide.title}
              body={slide.text}
              link={{
                text: slide.href,
                url: references[i],
              }}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </Col>
  );
}

function SlideInfo({ heading, body, link }: SliderInfoProps) {
  return (
    <Row className="g-0">
      <Col xs={12} md={8} className="mx-auto mb-5 pb-5">
        <Heading className="text-center mb-5">{heading}</Heading>
        <div className="tj px-2 mb-md-5 mb-lg-3">
          {body.map((par, i) => (
            <p key={i}>{par}</p>
          ))}
          <Link to={link.url} className="text-secondary">
            {link.text}
          </Link>
        </div>
      </Col>
    </Row>
  );
}

function SliderIcon({ icon }: any) {
  return <i className={`sliderIcon bi ${icon}`} />;
}

function useOnScreen(ref: MutableRefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)),
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [observer, ref]);

  return isIntersecting;
}
