import { AboutUs } from '../components/AboutUs';
import { SliderPhoto } from '../components/SliderPhoto';

import { ImageSeparator } from '../components/ImageSeparator';
import { SliderText } from '../components/SliderText';
import { EmbedYoutube } from '../components/EmbedYoutube';
import { Teachers } from '../components/Teachers';
import { Container } from 'react-bootstrap';
import { AboutCenter } from '../components/AboutCenter';
import { Schedule } from '../components/Schedule';
import { EmbedMap } from '../components/EmbedMap';

import { ANCHOR_ABOUT, ANCHOR_BUDDHISM, ANCHOR_TEACHERS } from '../constants';

import { useAssetId } from '../components/Utils';

import { useScrollOnMount } from '../misc/utils';

export function Main() {
  const top = useAssetId('spoiler_top_crop.png');
  const mid = useAssetId('spoiler_mid.png');

  useScrollOnMount();
  return (
    <>
      <SliderPhoto />

      <Container fluid="xxl" id={ANCHOR_ABOUT}>
        <AboutUs />
      </Container>

      <ImageSeparator image={top} />

      <Container fluid="lg" id={ANCHOR_BUDDHISM}>
        <SliderText />
      </Container>

      <EmbedYoutube />

      <Container fluid="xxl" id={ANCHOR_TEACHERS}>
        <Teachers />
      </Container>

      <ImageSeparator image={mid} bumper />

      <Container fluid="xxl">
        <AboutCenter />
      </Container>

      <Schedule />
      <EmbedMap />
    </>
  );
}
