import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { Heading, Spoiler } from './Utils';

export function AboutUs() {
  const { t } = useTranslation();
  return (
    <Col className="tj px-3 pt-md-5">
      <Heading className="text-secondary">{t('sections.about.title')}</Heading>
      <Row className="g-0">
        <Col xs={12} md={10} lg={9} className="mx-auto">
          <p className="pt-5">{t('sections.about.paragraphs.0')}</p>
          <p>{t('sections.about.paragraphs.1')}</p>

          <p className="my-5 text-secondary fs-5 fw-lg-bold">{t('sections.about.quote')}</p>

          {/* SHOW BORDER ON MD+ SCREEN */}
          <div className="d-none d-lg-block border-3 border-start border-secondary ps-4 ms-n4 mb-5">
            <p>{t('sections.about.paragraphs.2')}</p>
            <p>{t('sections.about.paragraphs.3')}</p>
          </div>

          {/* SHOW W/O BORDER ON SMALL SCREEN */}
          <div className="d-lg-none">
            <p>{t('sections.about.paragraphs.2')}</p>
          </div>

          <Spoiler>
            <p className="d-lg-none">{t('sections.about.paragraphs.3')}</p>
            <p>{t('sections.about.paragraphs.4')}</p>
            <p>{t('sections.about.paragraphs.5')}</p>
          </Spoiler>
        </Col>
      </Row>
    </Col>
  );
}
