import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { siteUrls } from '../data';
import { Link } from 'react-router-dom';
import { Heading, ResponsiveImage, useAssetBundle } from './Utils';
import {
  TEACHERS_PHOTO_FOLDER,
  TEACHER_ID_KARMAPA_16,
  TEACHER_ID_KARMAPA_17,
  TEACHER_ID_LAMA_OLE,
} from '../constants';

export function Teachers() {
  const { t } = useTranslation();
  const teachers = useTeachersInfo();

  return (
    <Col className="px-3 pt-md-5">
      <Heading className="text-secondary py-5">{t('sections.teachers.title')}</Heading>
      <Col className="mx-auto" xs={12} md={11} lg={10}>
        <Row className="g-0">
          {teachers.map((t, i) => (
            <TeacherInfo key={t.text.title} {...t.text} hrefs={t.links} photo={t.image} />
          ))}
        </Row>
      </Col>
    </Col>
  );
}

function TeacherInfo({ title, description, links, hrefs, photo }: TeacherInfoProps & TeacherPhoto) {
  return (
    <Col xs={12} className="mb-5">
      <h2 className="mb-5 fw-bold fs-5">{title}</h2>
      <Row className="g-0">
        <Col lg={3} className="d-none d-lg-block me-4">
          <Photo photo={photo} title={title} />
        </Col>
        <Col className="tj" lg={8}>
          <div className="d-lg-none">
            <Photo photo={photo} title={title} />
          </div>

          <div className="ps-0 ps-md-5 ps-lg-0">
            {description.map((t, i) => (
              <p key={i}>{t}</p>
            ))}

            {Object.keys(hrefs).map((key) => {
              return (
                <Link
                  className="d-block text-secondary link-underline link-underline-secondary link-underline-opacity-0 link-underline-opacity-100-hover"
                  to={hrefs[key]}
                  key={key}
                >
                  {links[key]}
                </Link>
              );
            })}
          </div>
        </Col>
      </Row>
    </Col>
  );
}

function Photo({ photo, title }: Partial<TeacherInfoProps> & TeacherPhoto) {
  return (
    <ResponsiveImage
      className="teachersImageMaxWidth float-start float-xl-none me-4"
      image={photo}
      alt={title}
    />
  );
}

function mapTeachersPhotoAndText(img: any[], translations: any[]) {
  return [
    // K16
    {
      image: img.find(({ id }) => id === TEACHER_ID_KARMAPA_16),
      text: translations[1],
      links: {
        details: siteUrls.BUDDHISM_UA_KARMAPA_16,
      },
    },
    // K17
    {
      image: img.find(({ id }) => id === TEACHER_ID_KARMAPA_17),
      text: translations[2],
      links: {
        details: siteUrls.BUDDHISM_UA_KARMAPA_17,
        site: siteUrls.SITE_KARMAPA_17,
      },
    },
    // LO
    {
      image: img.find(({ id }) => id === TEACHER_ID_LAMA_OLE),
      text: translations[0],
      links: {
        site: siteUrls.SITE_LAMA_OLE,
        qa: siteUrls.BUDDHISM_UA_LAMA_OLE_QA,
      },
    },
  ];
}

function useTeachersInfo() {
  const { t } = useTranslation();
  /*
  
  order mapping
  [KARMAPA_16, KARMAPA_17, LAMA_OLE];
  
  */

  const translations = t('sections.teachers.list', { returnObjects: true }) as TeacherInfoProps[];

  const IMG_TEACHERS = useAssetBundle(TEACHERS_PHOTO_FOLDER);
  return mapTeachersPhotoAndText(IMG_TEACHERS, translations);
}
