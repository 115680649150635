import { Col, Container, Row } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { MenuRegular } from './Header';
import { AddressUrl, contactInfo, siteUrls } from '../data';
import { Link } from 'react-router-dom';
import { ANCHOR_CONTACT } from '../constants';
import { FacebookEmbed } from './EmbedFacebook';

export function Footer() {
  const { t } = useTranslation();
  return (
    <>
      <Container fluid className="footer roundedTop bg-danger mt-n5 mt-md-0 position-relative py-5">
        <Container fluid="xxl">
          <Row>
            <Col xs={2} className="d-none d-lg-block">
              <Row className="mb-3">
                <h1 className="fs-5 fw-lg-bold">{t('footer.menu')}</h1>
              </Row>
              <Row>
                <MenuRegular />
              </Row>
            </Col>
            <Col xs={6} md={4} lg={3} className="order-2 order-lg-1" id={ANCHOR_CONTACT}>
              <Row className="mb-3">
                <h1 className="fs-5 fw-lg-bold">{t('footer.contacts')}</h1>
              </Row>
              <Row>
                <Contacts />
              </Row>
            </Col>
            <Col xs={6} md={4} lg={3} className="order-3 order-md-1 order-lg-2">
              <Row className="mb-3">
                <h1 className="fs-5 fw-lg-bold">{t('footer.resources')}</h1>
              </Row>
              <Row>
                <Resources />
              </Row>
            </Col>
            <Col xs={12} md={4} className="order-1 order-md-3 order-lg-3 pb-5 mb-5">
              <Row className="mb-3">
                <h1 className="fs-5 fw-lg-bold">{t('footer.news')}</h1>
              </Row>

              <FacebookEmbed />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="p-3 justify-content-center align-items-center text-center">
        © {new Date().getFullYear()} – {t('footer.copyright')}
      </Container>
    </>
  );
}

function Contacts() {
  const { t } = useTranslation();

  const data = t('footer.data', {
    returnObjects: true,
    phone1: contactInfo.phone1,
    phone2: contactInfo.phone2,
    email: contactInfo.email,
  });

  return (
    <Col>
      {Object.values(data).map((items, i) => (
        <ContactsWithMap key={i} isMap={i === 0} items={items} />
      ))}
    </Col>
  );
}
const resourceUrls: string[] = [
  siteUrls.SITE_BUDDHISM_UA,
  siteUrls.SITE_DW_ORG,
  siteUrls.SITE_ZNAMIN,
  siteUrls.SITE_LAMA_OLE,
  siteUrls.SITE_KARMAPA_17,
  siteUrls.SITE_EC,
];

function Resources() {
  const { t } = useTranslation();

  const sites = mapSites(
    resourceUrls,
    t('footer.sites', {
      returnObjects: true,
    }) as string[],
  );

  return (
    <Col>
      {sites.map((site, i) => (
        <Col key={i} className="mb-3">
          <Link
            to={site.url}
            className="secondaryText text-break  link-underline link-underline-opacity-0 link-underline-opacity-100-hover"
          >
            {site.title}
          </Link>
        </Col>
      ))}
    </Col>
  );
}

function mapSites(urls: string[], descr: string[]): Record<string, string>[] {
  return urls.reduce((acc, u, i) => [...acc, { url: u, title: descr[i] }], [] as any);
}

function ContactsWithMap({ isMap, items }: any) {
  return (
    <Col className="mb-4">
      {items.map((cont: string) => (
        <Col key={cont} className="secondaryText text-break">
          {!isMap && cont}
          {isMap && <Link to={AddressUrl}>{cont}</Link>}
        </Col>
      ))}
    </Col>
  );
}
