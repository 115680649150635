import { FacebookEmbedId } from '../data';

export function FacebookEmbed() {
  return (
    <div className="bg-white d-flex h-100 fbEmbed">
      <iframe
        src={`https://widget.tagembed.com/${FacebookEmbedId}?view`}
        style={{ width: '100%', overflow: 'auto' }}
        frameBorder="0"
        allowTransparency
        title="1"
      />
    </div>
  );
}
